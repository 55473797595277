import NavBar from '../components/NavBar/NavBar';
import SideBar from '../components/SideBar/SideBar';
import Footer from '../components/Footer/Footer';
import PageSection from '../components/PageSection/PageSection';
import { useState } from 'react';
import aboutImg from '../assets/about.svg';
import standLogo from '../assets/standwithcrypto-logo.svg';
import blockstartLogo from '../assets/blockstart-logo.svg';
import encodeLogo from '../assets/ENCODE_logo.png';

function AboutPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <PageSection
        title="About"
        text={
          <p style={{ color: 'white', textAlign: 'center' }}>
            The St Andrew’s Blockchain Society was founded in April 2022 by 7 alumni, including ex-CoinGecko Algorithmic Trading Writer, Finn Anderson. 
            Our partners include Stand with Crypto, BlockStart, and Encode London.
            We hold weekly meetings to discuss recent political, economic, and technological developments in blockchain, crypto, and decentralised finance (DeFi).
          </p>
        }
        img={aboutImg}
      />
      <div className="px-6 md:min-h-screen md:px-24">
        <h2 className="text-2xl font-bold text-white text-center">Our Partners</h2>
        <div className="flex flex-wrap justify-center gap-8 py-10">
          <div className="flex w-full flex-col space-y-8 md:pt-6 md:w-1/3">
            <a href="https://www.standwithcrypto.org" target="_blank" rel="noopener noreferrer">
              <img src={standLogo} alt="Stand with Crypto Logo" className="h-16 w-auto object-contain mx-auto" />
            </a>
            <p className="text-gray-300 text-center">
              Stand with Crypto, Coinbase-led organisation dedicated to unifying crypto and blockchain communities to garner favourable legislative support.
            </p>
          </div>
          <div className="flex w-full flex-col space-y-8 pt-6 md:w-1/3">
            <a href="https://blockstart.eu" target="_blank" rel="noopener noreferrer">
              <img src={blockstartLogo} alt="BlockStart Logo" className="h-16 w-auto object-contain mx-auto" />
            </a>
            <p className="text-gray-300 text-center">
              Blockstart, Scotland’s largest blockchain and web3 conference. On November 29th 2024, we hosted ‘BuildingBlocks’, a BlockStart-run conference.
            </p>
          </div>
          <div className="flex w-full flex-col space-y-8 pt-6 md:w-1/3 items-center">
            <a href="https://www.encode.club" target="_blank" rel="noopener noreferrer" className="items-center">
              <img src={encodeLogo} alt="Encode London Logo" className="h-16 w-auto object-contain mx-auto" />
            </a>
            <p className="text-gray-300 text-center">
              Encode London, global community of top developers, professionals and talented students in emerging tech.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutPage;
