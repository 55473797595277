import NavBar from '../components/NavBar/NavBar';
import SideBar from '../components/SideBar/SideBar';
import Footer from '../components/Footer/Footer';
import { useState } from 'react';

function InvestmentReports2425Page() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
return (
    <>
        <SideBar isOpen={isOpen} toggle={toggle} />
        <NavBar toggle={toggle} />
        <div className="bg-black min-h-screen text-white" style={{ paddingTop: '8rem' }}>
                <div style={{ position: 'relative', width: '100%', height: '0', paddingTop: '56.2225%', paddingBottom: '0', boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)', marginTop: '1.6em', marginBottom: '0.9em', overflow: 'hidden', borderRadius: '8px', willChange: 'transform' }}>
                    <iframe loading="lazy" style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', border: 'none', padding: '0', margin: '0' }}
                        src="https://www.canva.com/design/DAGe0J_NG2g/-vO5iayaPTZnAt3om5WPKg/view?embed" allowFullScreen allow="fullscreen">
                    </iframe>
                </div>
                <a className="text-lg font-bold text-primary hover:underline" href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGe0J_NG2g&#x2F;-vO5iayaPTZnAt3om5WPKg&#x2F;view?utm_content=DAGe0J_NG2g&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener">Blockchain Investment Report Winter 2024</a>
        </div>
        <Footer />
    </>
);
}

export default InvestmentReports2425Page;
