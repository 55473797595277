import { React, useState } from 'react';
import SideBar from '../components/SideBar/SideBar';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';

const ContactPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <div className="min-h-screen pt-40 text-white">
        <p className="text-xl">
          Contact Us - For enquiries, partnerships or additional information, please contact us at 
          <a
            href="mailto:blockchain@st-andrews.ac.uk"
            className="no-underline hover:opacity-75 underline"
          >
            {' '}blockchain@st-andrews.ac.uk{' '}
          </a> 
          or message us on our socials. If you wish to join, please purchase a membership from the union website by clicking ‘Join’.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
